import React from "react";
import { Formik } from "formik";
import styled from "styled-components";
import { darken } from "polished";

function ContactForm() {
  const Status = ({ status }) => {
    switch (status) {
      case "SUCCESS":
        return (
          <SuccessMessage>
            Your message has been sent! We'll be in touch soon!
          </SuccessMessage>
        );
      case "ERROR":
        return (
          <ErrorMessage>
            There was an error sending your message, please try again.
          </ErrorMessage>
        );
      default:
        return null;
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        phone: "",
        message: ""
      }}
      validate={validation}
      onSubmit={submitForm}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        status,
        touched,
        values
      }) => (
        <Form onSubmit={handleSubmit}>
          <Status status={status} />

          <InputGroup hasError={errors.name && touched.name}>
            <label htmlFor="name">Name</label>
            <Input
              type="text"
              id="name"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            <small>{errors.name && touched.name && errors.name}</small>
          </InputGroup>

          <InputGroup hasError={errors.phone && touched.phone}>
            <label htmlFor="phone">Phone Number</label>
            <Input
              type="tel"
              id="phone"
              name="phone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
            />
            <small>{errors.phone && touched.phone && errors.phone}</small>
          </InputGroup>

          <InputGroup hasError={errors.email && touched.email}>
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              id="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <small>{errors.email && touched.email && errors.email}</small>
          </InputGroup>

          <InputGroup hasError={errors.message && touched.message}>
            <label htmlFor="message">Message</label>
            <Input
              as="textarea"
              id="message"
              name="message"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
            />
            <small>{errors.message && touched.message && errors.message}</small>
          </InputGroup>

          <Button type="submit" disabled={status === "PENDING"}>
            {status === "PENDING" ? "📨 Sending..." : "✉️ Send"}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

const Form = styled.form`
  display:block;
  border: 1px dashed #ddd;
  border-radius: 0.25rem;
  padding: ${props => props.theme.space[4]};

  ${props => props.theme.mq.md} {
    width: 70rem;
    margin-left: ${props => props.theme.space[4]};
  }
`

const Input = styled.input`
  background-clip: padding-box;
  border-color: #ddd;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 2px;
  display: block;
  height: ${props => (props.as === "textarea" ? "4rem" : "inherit")};
  padding: ${props => props.theme.space[2]};
  width: 100%;
  box-sizing: border-box;

  :focus {
    border-color: ${props => props.theme.colors.primary};
    outline: 0;
  }
`;

const InputGroup = styled.div`
  & + & {
    margin-top: ${props => props.theme.space[3]};
  }

  label {
    font-weight: bold;
    display: block;
    margin-bottom: ${props => props.theme.space[1]};
    color: ${props => (props.hasError ? "#dc3545" : "inherit")};
  }

  small {
    font-size: ${props => props.theme.fontSizes[0]};
    color: ${props => (props.hasError ? "#dc3545" : "inherit")};
  }

  & ${Input} {
    border-color: ${props => (props.hasError ? "#dc3545" : "#ddd")};

    :focus {
      border-color: ${props =>
        props.hasError ? "#dc3545" : props.theme.colors.primary};
    }
  }
`;

const Button = styled.button`
  background-color: ${props => props.theme.colors.primary};
  border-radius: 0.25rem;
  border: none;
  color: white;
  display: inline-block;
  padding: ${props => props.theme.space[2]} ${props => props.theme.space[3]};
  user-select: none;
  font-weight: bold;
  cursor: pointer;

  :hover,
  :active {
    background-color: ${props => darken(0.2, props.theme.colors.primary)};
  }

  ${InputGroup} + & {
    margin-top: ${props => props.theme.space[3]};
  }
`;

const SuccessMessage = styled.div`
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 1rem;
  padding: ${props => props.theme.space[2]};

  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
`;

const ErrorMessage = styled(SuccessMessage)`
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
`;

const validation = ({ name, email, phone, message }) => {
  let errors = {};

  if (!name) {
    errors.name = "Please enter your name.";
  }

  if (!email) {
    errors.email = "Please enter your email address.";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = "Please enter a valid email address.";
  }

  if (!phone) {
    errors.phone = "Please enter your phone number.";
  } else if (
    !/^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|#)\d{3,4})?$/i.test(
      phone
    )
  ) {
    errors.phone = "Please enter a valid phone number.";
  }

  if (!message) {
    errors.message = "Please tell us why you're getting in touch.";
  }

  return errors;
};

const submitForm = (values, { setStatus, resetForm }) => {
  setStatus("PENDING");

  fetch("/api/send-message", {
    method: "POST",
    body: JSON.stringify(values)
  })
    .then(response => response.json())
    .then(response => {
      resetForm();
      setStatus("SUCCESS");
    })
    .catch(error => {
      setStatus("ERROR");
    });
};

export default ContactForm;
